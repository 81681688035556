
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.add-popular-modal {
  &__search {
    margin-bottom: $base-gutter;
  }
}
